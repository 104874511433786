var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('v-container',[(_vm.isLoading)?_c('Loading'):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('global.notFound'))+" ")]},proxy:true},{key:"item.orderId",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/liff/history/" + (item.orderId) + "?type=" + (item.isGroup ? 'group' : 'normal'))}},[_vm._v(" "+_vm._s(item.orderId)+" ")])]}},{key:"item.branch",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.branch === 'trang' ? _vm.$t('global.trang') : _vm.$t('global.sathon'))+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatMiniDate")(item.schedule.date))+" ")]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(" "+_vm._s(_vm.formatSchedule(item.schedule.startTime, item.schedule.endTime))+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.statusColor(item.orderStatus)},[_vm._v(" "+_vm._s(_vm.$t(("history.orderStatus." + (item.orderStatus))))+" ")])]}},{key:"item.atkStatus",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.atkStatus)?_c('div',{class:_vm.atkStatusColor(item.atkStatus)},[_vm._v(" "+_vm._s(_vm.$t("global.atkStatusTrue"))+" ")]):_c('div',{class:_vm.atkStatusColor(item.atkStatus)},[_vm._v(" "+_vm._s(_vm.$t("global.atkStatusFalse"))+" ")])])]}},{key:"item.detail",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-btn',{staticClass:"white--text mt-2",attrs:{"to":("/liff/history/" + (item.orderId) + "?type=" + (item.isGroup ? 'group' : 'normal')),"color":"#3BAEF8","block":"","small":"","depressed":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account-details ")]),_vm._v(" "+_vm._s(_vm.$t('global.detail'))+" ")],1)],1),_c('v-row',[(!item.isGroup)?_c('v-btn',{staticClass:"white--text mt-2",attrs:{"to":("/liff/atk/" + (item.orderId)),"color":"#3BAEF8","small":"","block":"","depressed":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-needle ")]),_vm._v(" "+_vm._s(_vm.$t('global.atkInfo'))+" ")],1):_c('v-btn',{staticClass:"white--text mt-2",attrs:{"to":("/liff/history/file/" + (item.orderId)),"color":"#3BAEF8","small":"","block":"","depressed":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-file-document ")]),_vm._v(" "+_vm._s(_vm.$t('booking.visitConsentSend'))+" ")],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }