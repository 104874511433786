<template>
  <v-form>
    <v-container>
      <Loading v-if="isLoading" />
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="items"
            hide-default-footer>
            <template #no-data>
              {{ $t('global.notFound') }}
            </template>
            <template #item.orderId="{ item }">
              <router-link :to="`/liff/history/${item.orderId}?type=${item.isGroup ? 'group' : 'normal'}`">
                {{ item.orderId }}
              </router-link>
            </template>
            <template #item.branch="{ item }">
              {{ item.branch === 'trang' ? $t('global.trang') : $t('global.sathon') }}
            </template>
            <template #item.date="{ item }">
              {{ item.schedule.date | formatMiniDate }}
            </template>
            <template #item.time="{ item }">
              <p>
                {{ formatSchedule(item.schedule.startTime, item.schedule.endTime) }}
              </p>
            </template>
            <template #item.status="{ item }">
              <div :class="statusColor(item.orderStatus)">
                {{ $t(`history.orderStatus.${item.orderStatus}`) }}
              </div>
            </template>
            <template #item.atkStatus="{ item }">
              <div>
                <div
                  v-if="item.atkStatus"
                  :class="atkStatusColor(item.atkStatus)">
                  {{ $t(`global.atkStatusTrue`) }}
                </div>
                <div
                  v-else
                  :class="atkStatusColor(item.atkStatus)">
                  {{ $t(`global.atkStatusFalse`) }}
                </div>
              </div>
            </template>
            <template #item.detail="{ item }">
              <v-row>
                <v-btn
                  :to="`/liff/history/${item.orderId}?type=${item.isGroup ? 'group' : 'normal'}`"
                  color="#3BAEF8"
                  class="white--text mt-2"
                  block
                  small
                  depressed>
                  <v-icon left>
                    mdi-account-details
                  </v-icon>
                  {{ $t('global.detail') }}
                </v-btn>
              </v-row>
              <v-row>
                <v-btn
                  v-if="!item.isGroup"
                  :to="`/liff/atk/${item.orderId}`"
                  color="#3BAEF8"
                  class="white--text mt-2"
                  small
                  block
                  depressed>
                  <v-icon left>
                    mdi-needle
                  </v-icon>
                  {{ $t('global.atkInfo') }}
                </v-btn>
                <v-btn
                  v-else
                  :to="`/liff/history/file/${item.orderId}`"
                  color="#3BAEF8"
                  class="white--text mt-2"
                  small
                  block
                  depressed>
                  <v-icon left>
                    mdi-file-document
                  </v-icon>
                  {{ $t('booking.visitConsentSend') }}
                </v-btn>
              </v-row>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import liff from '@line/liff'
import { mapActions } from 'vuex'
import Loading from '@/components/Loading.vue'
import axios from '../../../../utils/axios.js'

export default {
  components: {
    Loading
  },
  data () {
    return {
      items: [],
      lineUid: '',
      isLoading: false
    }
  },
  computed: {
    headers () {
      return [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'orderId'
        },
        {
          text: this.$t('global.branch'),
          align: 'start',
          sortable: false,
          value: 'branch'
        },
        {
          text: this.$t('global.date'),
          align: 'start',
          sortable: false,
          value: 'date'
        },
        {
          text: this.$t('global.time'),
          align: 'start',
          sortable: false,
          value: 'time'
        },
        {
          text: this.$t('global.status'),
          align: 'start',
          sortable: false,
          value: 'status'
        },
        // {
        //   text: this.$t('global.atkStatusDetailShort'),
        //   align: 'start',
        //   sortable: false,
        //   value: 'atkStatus'
        // },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'detail'
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'atk'
        }
      ]
    }
  },
  async mounted () {
    await this.liffInit()
    await this.checkLogined()
    await this.getOrder()
  },
  methods: {
    ...mapActions({
      setLineUid: 'booking/setLineUid',
      setIsMember: 'booking/setIsMember'
    }),
    statusColor (value) {
      switch (value) {
        case 'waiting':
          return 'orange--text'
        case 'pending':
          return 'orange--text'
        case 'paid':
          return 'green--text'
        case 'confirmed':
          return 'green--text data'
        case 'canceled':
          return 'red--text'
        case 'rejected':
          return 'red--text'
        default:
          return ''
      }
    },
    atkStatusColor (value) {
      switch (value) {
        case true:
          return 'green--text'
        case false:
          return 'orange--text'
        default:
          return ''
      }
    },
     async liffInit () {
      await liff.init({ liffId: process.env.VUE_APP_LIFF_ID })
      if (liff.isLoggedIn()) {
        const profile = await liff.getProfile()
        const { userId } = profile
        this.lineUid = userId
        this.setLineUid(this.lineUid)
      } else {
        liff.login({ redirectUri: `${process.env.VUE_APP_FRONTEND_URL}/liff/history/atk` })
      }
    },
    async getOrder () {
      try {
        this.isLoading = true
        const { member } = JSON.parse(localStorage.getItem('member'))
        const { data: { data } } = await this.axios.get(`${process.env.VUE_APP_API_URL}/order/member/${member.memberId}?atkStatus=false`)
        this.items = data
      } catch (error) {
        this.error = error
      } finally {
        this.isLoading = false
      }
    },
    async checkLogined () {
      try {
        const { data: { data } } = await axios.get(`/member/line/${this.lineUid}`)
        if (!data.member) {
          localStorage.removeItem('member')
          this.$router.push('/liff/register')
        } else {
          localStorage.setItem('member', JSON.stringify(data))
          this.setLineUid(data.member.lineUid)
          this.setIsMember(true)
        }
      } catch (error) {
        this.setIsMember(false)
        localStorage.removeItem('member')
      }
    },
     formatSchedule (startTime, endTime) {
      if (startTime === '00:00' && endTime === '23:59') {
        return 'Allday'
      }
      return `${startTime} - ${endTime}`
    }
  }
}
</script>
